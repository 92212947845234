import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Stack,
  Typography,
  Paper,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Container,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  IconButton,
  Autocomplete,
  Select,
  MenuItem,
} from "@mui/material";

import Btn from "../Components/Btn";
import http from "../http";
import GlobalLoading from "../Components/loading";
import GlobalAlert from "../Components/alert";
import DeleteIcon from "@mui/icons-material/Delete";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SearchIcon from "@mui/icons-material/Search";
import AvatarIcon from "../Assets/images/avatar.png";
import TakePicture from "./AddUser/TakePicture";
import MyDataGrid from "../Components/MyDataGrid";
import axios from "axios";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const defaultFormFields = {
  first_name: "",
  last_name: "",
  customer_id: "", // member_id
  member_id: "",
  email: "",
  category: "",
  group_id: "",
  image_s3_path: "",
};

export default function UpdateUser() {
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [userInfo, setUserInfo] = useState(null);
  const [deleteUserInfo, setDeleteUserInfo] = useState(null);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [s3Info, setS3Info] = useState(null);
  const [updateType, setUpdateType] = useState(1);
  const [isCapturingPicture, setIsCapturingPicture] = useState(false);
  const [s3ImagePath, setS3Path] = useState(undefined);
  const [previewImage, setPreviewImag] = useState(undefined);
  const [category, setCategory] = useState("Member");
  const [searchType, setSearchType] = useState(1);
  const searchTypeRef = useRef(1);
  const [dataSource, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const pageRef = useRef(0);

  const { customer_id, first_name, member_id } = formFields;

  const columns = [
    {
      field: "customer_id",
      headerName: "Customer Id",
      width: 150,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.customer_id}</span>;
      },
    },
    {
      field: "member_id",
      headerName: "Member Id",
      width: 150,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.member_id}</span>;
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.category}</span>;
      },
    },
    {
      field: "optional_a",
      headerName: "Optional A",
      width: 150,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.optional_a}</span>;
      },
    },
    {
      field: "optional_b",
      headerName: "Optional B",
      width: 150,
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.optional_b}</span>;
      },
    },
    {
      field: "first_name",
      width: 150,
      headerName: "First Name",
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.first_name}</span>;
      },
    },
    {
      field: "last_name",
      width: 150,
      headerName: "Last Name",
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.last_name}</span>;
      },
    },
    {
      field: "image_s3_path",
      width: 150,
      headerName: "Picture",
      renderCell: ({ row }) => {
        return (
          <img
            style={{
              width: 70,
              height: 70,
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={row.image_s3_path}
            alt=""
          />
        );
      },
    },
    {
      field: "group_id",
      width: 150,
      headerName: "Club Id",
      renderCell: ({ row }) => {
        return <span style={{ fontSize: 12 }}>{row.group_id}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      renderCell: ({ row }) => (
        <div>
          <IconButton
            onClick={() => {
              handleEditByRow(row);
            }}
          >
            <BorderColorIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteUserInfo(row);
              setDeleteDialogOpened(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (updateType == 2 && userInfo.customer_id) {
      handleGetS3Info(userInfo.customer_id);
    }
  }, [updateType]);

  useEffect(() => {
    handleGetAllCustomer({
      page: pageRef.current,
    });
  }, []);

  const handleReset = () => {
    setFormFields(defaultFormFields);
    setUserInfo(null);
    setDeleteDialogOpened(false);
    setS3Info(null);
    setUpdateType(1);
    setIsCapturingPicture(false);
    setS3Path(undefined);
    setPreviewImag(undefined);
  };

  // 滚动到页面底部
  function scrollToBottom() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth", // 使用平滑滚动效果
      });
    }, 300);
  }

  const handleEditByRow = (record = {}) => {
    try {
      setUserInfo(record);
      setCategory(record.category);
      setPreviewImag(record.image_s3_path);
      scrollToBottom();
    } catch (e) {}
  };

  const handleTextFieldsChanged = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleUserInfoTextFieldsChanged = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleDelete = async (e) => {
    try {
      GlobalLoading.show();
      const res = await http.delete(`/customers/${deleteUserInfo.customer_id}`);
      GlobalLoading.hide();
      console.log("handleDelete res", res);
      if (res.status_code === 200) {
        GlobalAlert.success({
          msg: "Delete Success !",
          finish: () => {
            handleReset();
            handleGetAllCustomer({
              page: pageRef.current,
            });
          },
        });
      } else {
        GlobalAlert.error({
          msg: res.detail || "Delete Failed !",
        });
      }
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.error({
        msg: "Delete Failed !",
      });
    }
  };

  const handleGetS3Info = async (customer_id) => {
    try {
      GlobalLoading.show();
      const res = await http.get(
        `/customers/presignURL?customer_id=${customer_id}`
      );
      GlobalLoading.hide();
      console.log("res==>>", res);
      setS3Info(res);
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg: e,
      });
    }
  };

  const handleUploadImageToS3 = async (file) => {
    try {
      const payload = new FormData();
      payload.append("Content-Type", s3Info.fields["Content-Type"]);
      payload.append("key", s3Info.fields["key"]);
      payload.append("AWSAccessKeyId", s3Info.fields["AWSAccessKeyId"]);
      payload.append("policy", s3Info.fields["policy"]);
      payload.append("signature", s3Info.fields["signature"]);
      payload.append("file", file);
      handleAddAvatar(file);
      if (s3Info && s3Info.url) {
        GlobalLoading.show();
        const res = await axios.post(s3Info.url, payload, {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        });
        GlobalLoading.hide();
        GlobalAlert.success({
          msg: "Upload Success !",
          duration: 6000,
        });
        setS3Path(s3Info.fields["key"]);
      }
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg: "Upload Error !",
      });
    }
  };

  const handleUpdateAvatar = async () => {
    try {
      if (!s3ImagePath) {
        GlobalAlert.error({
          msg: "Please Upload Picture !",
        });
        return false;
      }
      GlobalLoading.show();
      const res = await http.put(
        `/customers/${userInfo.customer_id}/image?image_s3_key=${s3ImagePath}`
      );
      GlobalLoading.hide();
      console.log("udate avatar res ==>>", res);
      if (res.status_code == 500) {
        GlobalAlert.error({
          msg: res.detail,
        });
      } else {
        GlobalAlert.success({
          msg: "Update Success !",
        });
        handleReset();
      }
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("user", userInfo);
    try {
      const payload = {
        group_id: userInfo.group_id,
        customer_id: userInfo.customer_id,
        member_id: userInfo.member_id ? userInfo.member_id : undefined,
        image_s3_path: userInfo.image_s3_path,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.email ? userInfo.email : undefined,
        category: category,
        optional_a: userInfo.optional_a,
        optional_b: userInfo.optional_b,
        admin_approved: true,
      };
      GlobalLoading.show();
      const res = await http.put(`/customers/${userInfo.customer_id}`, payload);
      GlobalLoading.hide();
      console.log("res==>>", res);
      GlobalAlert.success({
        msg: "Update Success !",
        finish: () => {
          handleReset();
        },
      });
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.error({
        msg:
          e?.response?.data?.detail?.[0]?.msg ||
          e?.message ||
          "Update Failed !",
      });
      console.log(e);
    }
  };

  const handleGetAllCustomer = async (opt = {}) => {
    const { page = 0 } = opt;
    try {
      GlobalLoading.show();
      const res = await http.get(`/customers/search/group?&page=${page + 1}`);
      console.log("res", res);
      GlobalLoading.hide();
      if (res.items?.length > 0) {
        setData(res.items);
        setRowCount(res.total);
        pageRef.current = page;
        setPage(page);
      }
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const handleSearchCustomer = async (e) => {
    // search by customer_id
    if (searchType === 1) {
      try {
        e.preventDefault();
        GlobalLoading.show();
        const res = await http.get(`/customers/${customer_id}`);
        GlobalLoading.hide();
        if (res.status_code == 500) {
          GlobalAlert.error({
            msg: res.detail,
          });
        } else {
          setData([
            {
              ...res,
            },
          ]);
        }
      } catch (e) {
        GlobalLoading.hide();
        GlobalAlert.error({
          msg: "Error !",
        });
      }
    }

    if (searchType === 5) {
      try {
        e.preventDefault();
        GlobalLoading.show();
        const res = await http.get(
          `/customers/search/name_filter?first_name=${first_name} `
        );
        GlobalLoading.hide();
        console.log("res==>>", res);
        if (res.status_code == 500) {
          GlobalAlert.error({
            msg: res.detail,
          });
        } else {
          setData(res.items || []);
        }
      } catch (e) {
        GlobalLoading.hide();
        GlobalAlert.error({
          msg: "Error !",
        });
      }
    }

    // search by member_id
    if (searchType === 4) {
      try {
        e.preventDefault();
        GlobalLoading.show();
        const res = await http.get(
          `/customers/search/member_id?member_id=${formFields.member_id}`
        );
        console.log("rres=", res);
        GlobalLoading.hide();
        if (res.status_code == 500) {
          GlobalAlert.error({
            msg: res.detail,
          });
        } else {
          setData(res.items || []);
        }
      } catch (e) {
        GlobalLoading.hide();
        GlobalAlert.error({
          msg: "Error !",
        });
      }
    }

    // search by username
    if (searchType === 2) {
      try {
        e.preventDefault();
        const params = {
          first_name: formFields.first_name,
          last_name: formFields.last_name,
        };
        GlobalLoading.show();
        // Celeste
        const res = await http.get(`/customers/search/name`, {
          params,
        });
        console.log("search/name=", res);
        GlobalLoading.hide();
        GlobalLoading.hide();
        if (res.status_code == 500) {
          GlobalAlert.error({
            msg: res.detail,
          });
        } else {
          setData(res.items || []);
        }
      } catch (e) {
        GlobalLoading.hide();
        GlobalAlert.error({
          msg: "Error !",
        });
      }
    }

    // search by category
    if (searchType === 3) {
      try {
        e.preventDefault();
        GlobalLoading.show();
        const params = {
          category: formFields.category,
        };
        const res = await http.get(`/customers/search/category`, {
          params,
        });
        console.log("category rres=", res);
        GlobalLoading.hide();
        if (res.status_code == 500) {
          GlobalAlert.error({
            msg: res.detail,
          });
        } else {
          setData(res.items || []);
        }
      } catch (e) {
        GlobalLoading.hide();
        GlobalAlert.error({
          msg: "Error !",
        });
      }
    }
  };

  const handleAddAvatar = (file) => {
    const reader = new FileReader();

    // 文件加载完成时的回调函数
    reader.onload = function (event) {
      // 将文件读取结果作为图片的 src 属性值
      setPreviewImag(event.target.result);
    };
    // 读取文件内容
    reader.readAsDataURL(file);
  };

  const handleImgsUpload = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const uploadedFiles = [...e.target.files].slice(0, 3);
    handleUploadImageToS3(uploadedFiles[0]);
  };

  const pictureCaptured = (img) => {
    setIsCapturingPicture(false);
    console.log("img==>>", img);
    handleUploadImageToS3(img);
  };

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <Stack>
            <div style={{ width: "100%" }}>
              <h3
                style={{
                  marginBottom: 32,
                }}
              >
                Step 1: Search Customer
              </h3>
              <form onSubmit={handleSearchCustomer}>
                <Grid container spacing={10} alignItems="center">
                  <Grid
                    item
                    lg={5}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      value={searchType}
                      style={{ width: "30%" }}
                      onChange={(e) => {
                        const value = e.target.value;
                        searchTypeRef.current = value;
                        setSearchType(value);
                      }}
                    >
                      <MenuItem value={1}>Customer Id</MenuItem>
                      <MenuItem value={4}>Member Id</MenuItem>
                      <MenuItem value={2}>Username</MenuItem>
                      <MenuItem value={5}>Username(Filter)</MenuItem>
                      <MenuItem value={3}>Category</MenuItem>
                    </Select>
                    {searchType === 1 && (
                      <TextField
                        label="Customer Id"
                        variant="outlined"
                        name="customer_id"
                        value={formFields["customer_id"]}
                        onChange={handleTextFieldsChanged}
                        style={{ width: "65%" }}
                        required
                      />
                    )}
                    {searchType === 5 && (
                      <TextField
                        label="Username(Filter)"
                        variant="outlined"
                        name="first_name"
                        value={formFields["first_name"]}
                        onChange={handleTextFieldsChanged}
                        style={{ width: "65%" }}
                        required
                      />
                    )}
                    {searchType === 4 && (
                      <TextField
                        label="Member Id"
                        variant="outlined"
                        name="member_id"
                        value={formFields["member_id"]}
                        onChange={handleTextFieldsChanged}
                        style={{ width: "65%" }}
                        required
                      />
                    )}
                    {searchType === 2 && (
                      <TextField
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        value={formFields["first_name"]}
                        onChange={handleTextFieldsChanged}
                        style={{ width: "35%" }}
                      />
                    )}
                    {searchType === 2 && (
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        value={formFields["last_name"]}
                        onChange={handleTextFieldsChanged}
                        style={{ width: "35%" }}
                      />
                    )}
                    {searchType === 3 && (
                      <TextField
                        label="Category"
                        variant="outlined"
                        name="category"
                        value={formFields["category"]}
                        onChange={handleTextFieldsChanged}
                        style={{ width: "65%" }}
                        required
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Btn type="submit" startIcon={<SearchIcon />}>
                      Search
                    </Btn>
                  </Grid>
                </Grid>
              </form>

              <Grid container spacing={2} style={{ marginTop: "2px" }}>
                <Grid item lg={12} xs={12} style={{ height: "500px" }}>
                  <MyDataGrid
                    rows={dataSource}
                    columns={columns}
                    density="comfortable"
                    rowHeight={90}
                    rowsPerPageOptions={[20]}
                    getRowId={(row) => {
                      return row.customer_id + "" + Math.random() * 1000000000;
                    }}
                    pageSize={20}
                    page={page}
                    onPageChange={(newPage) => {
                      handleGetAllCustomer({
                        page: newPage,
                      });
                    }}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                  />
                </Grid>
              </Grid>

              {userInfo && (
                <h3
                  style={{
                    marginTop: 64,
                    marginBottom: 32,
                  }}
                >
                  Step2: Update Customer Info
                </h3>
              )}

              {userInfo && (
                <Grid
                  container
                  spacing={10}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControl
                      style={{ marginBottom: 48, textAlign: "center" }}
                    >
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Select Update Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) => {
                          setUpdateType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Update Info"
                          checked={updateType == 1}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Update Picture"
                          checked={updateType == 2}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {userInfo && updateType == 1 && (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={5}>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        value={userInfo["first_name"]}
                        onChange={handleUserInfoTextFieldsChanged}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        value={userInfo["last_name"]}
                        onChange={handleUserInfoTextFieldsChanged}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={userInfo["email"]}
                        onChange={handleUserInfoTextFieldsChanged}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        label="Club Id"
                        variant="outlined"
                        name="group_id"
                        value={userInfo["group_id"]}
                        onChange={handleUserInfoTextFieldsChanged}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        label="Member Id"
                        variant="outlined"
                        name="member_id"
                        value={userInfo["member_id"]}
                        onChange={handleUserInfoTextFieldsChanged}
                        fullWidth
                        helperText="Only required for gym"
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Autocomplete
                        value={category}
                        options={[
                          "Member",
                          "Guest",
                          "Not allowed",
                          "Test",
                          "Other1",
                          "Other2",
                        ]}
                        onChange={(e, val) => setCategory(val)}
                        renderInput={(params) => (
                          <TextField
                            label="Category"
                            {...params}
                            required
                            fullWidth
                          />
                        )}
                        autoFocus
                      />
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <TextField
                        label="Optional A"
                        variant="outlined"
                        name="optional_a"
                        value={userInfo["optional_a"]}
                        onChange={handleUserInfoTextFieldsChanged}
                        fullWidth
                      />
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <TextField
                        label="Optional B"
                        variant="outlined"
                        name="optional_b"
                        value={userInfo["optional_b"]}
                        onChange={handleUserInfoTextFieldsChanged}
                        fullWidth
                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <Btn type="submit" startIcon={<BorderColorIcon />}>
                        Submit
                      </Btn>
                    </Grid>
                    <Grid item lg={4} xs={12}></Grid>
                    <Grid item lg={4} xs={12}></Grid>
                    <Grid item lg={4} xs={12}></Grid>
                    <Grid item lg={4} xs={12}></Grid>
                  </Grid>
                </form>
              )}

              {userInfo && updateType == 2 && (
                <Grid container spacing={5}>
                  <Grid item lg={3} xs={12}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                      <Image src={previewImage} />
                    </Stack>
                    <br />
                    <Stack direction="row" spacing={1} justifyContent="center">
                      <Btn variant="outlined" component="label">
                        Upload Picture
                        <input
                          type="file"
                          name="pictures"
                          accept="image/jpg,image/jpeg"
                          onChange={handleImgsUpload}
                          multiple
                          hidden
                        />
                      </Btn>
                      <Btn
                        variant="outlined"
                        onClick={() => setIsCapturingPicture(true)}
                      >
                        Capture
                      </Btn>
                    </Stack>

                    <Grid item lg={12} xs={12} style={{ margin: "32px 0" }}>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        justifyContent="center"
                      >
                        <Btn
                          disabled={!s3ImagePath}
                          startIcon={<FactCheckIcon />}
                          onClick={handleUpdateAvatar}
                        >
                          Submit
                        </Btn>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Dialog
                    sx={{ bgcolor: "transparent" }}
                    open={isCapturingPicture}
                    onClose={() => setIsCapturingPicture(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <TakePicture pictureCaptured={pictureCaptured} />
                  </Dialog>
                </Grid>
              )}

              <Dialog
                open={deleteDialogOpened}
                onClose={() => setDeleteDialogOpened(false)}
                maxWidth="md"
              >
                <DeleteDialog
                  handleClose={() => setDeleteDialogOpened(false)}
                  handleSubmit={handleDelete}
                />
              </Dialog>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

function DeleteDialog({ handleClose, handleSubmit }) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit();
      }}
    >
      <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          type="submit"
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </form>
  );
}

const Image = ({ src, onDelete }) => {
  console.log("src==>>", src);
  return (
    <Box
      sx={{
        position: "relative",
        "&:hover span": {
          display: "block",
        },
      }}
    >
      <img
        style={{ width: 150, maxHeight: 227, borderRadius: 10 }}
        src={src || AvatarIcon}
        alt=""
      />

      {src && (
        <span
          style={{
            // display: 'none',
            position: "absolute",
            bottom: 7,
            right: 3,
            padding: 0,
            background: "#ffffff55",
            borderRadius: 8,
          }}
        >
          <IconButton
            onClick={() => onDelete()}
            sx={{ color: "black" }}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </span>
      )}
    </Box>
  );
};
