import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  Button,
  TextField,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MyDataGrid from "../Components/MyDataGrid";
import GlobalLoading from "../Components/loading";
import GlobalAlert from "../Components/alert";
import http from "../http";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";

let timer = null;
const TIMER_COUNT = 5000;

export default function Unrecognized({
  insertData,
  unrecognized,
  reloadPage = () => {},
}) {
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [record, setRecord] = useState({});
  const [visible, setVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    member_id: "",
    group_id: "",
    image_s3_path: "",
    category: "",
  });

  const [page, setPage] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const pageRef = useRef(0);

  useEffect(() => {
    if (insertData && JSON.stringify(insertData) !== "{}") {
      const newData = [...dataSource];
      newData.unshift(insertData);
      setDataSource(newData);
    }
  }, [JSON.stringify(insertData)]);

  useEffect(() => {
    handleGetUnApproved({
      page: pageRef.current,
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleGetUnApproved = async (opt = {}) => {
    const { hideLoading, page = 0 } = opt;
    clearTimeout(timer);
    try {
      if (!hideLoading) {
        GlobalLoading.show();
      }
      // 支持page翻页
      const res = await http.get(
        `/checkin/?is_recognized=False&page=${page + 1}`
      );
      setDataSource(res.items);
      setRowCount(res.total);
      setPage(page);
      pageRef.current = page;
      if (!hideLoading) {
        GlobalLoading.hide();
      }
      // timer = setTimeout(() => {
      //   const pathname = window.location.pathname;
      //   if (pathname === "/history") {
      //     handleGetUnApproved({
      //       page: pageRef.current,
      //       hideLoading: true,
      //     });
      //   }
      // }, TIMER_COUNT);
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        member_id: userInfo.member_id ? userInfo.member_id : undefined,
        group_id: record.group_id,
        email: userInfo.email ? userInfo.email : undefined,
        image_s3_path: record.image_s3_path,
        category: userInfo.category,
      };
      GlobalLoading.show();
      const res = await http.post("/customers/quick-add", payload);
      GlobalLoading.hide();
      console.log("submit res==>>", res);
      if (res.status_code) {
        GlobalAlert.error({
          msg: res.detail || "Add Failed !",
        });
      } else {
        GlobalAlert.success({
          msg: "Add Success !",
          finish: () => {
            setVisible(false);
            setRecord({});
            setUserInfo({});
          },
        });
      }
    } catch (err) {
      console.log("submit err", err);
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg: err.message,
      });
    }
  };

  const handleDeleteImage = async (e) => {
    try {
      setDeleteDialogOpened(false);
      // record
      GlobalLoading.show();
      const res = await http.delete(`/checkin/${record.checkin_id}`);
      GlobalLoading.hide();
      GlobalAlert.success({
        msg: "Delete Success !",
      });
      reloadPage();
    } catch (err) {
      console.log("err==>>", err);
      GlobalLoading.hide();
      GlobalAlert.error({
        msg: e?.response?.data?.detail || e?.message || "Delete Failed !",
      });
    }
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: ({ row }) => (
        <div>
          <IconButton
            onClick={() => {
              setRecord(row);
              setUserInfo(row);
              setVisible(true);
            }}
          >
            <AddCircleOutlineIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setRecord(row);
              setDeleteDialogOpened(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "image_s3_path",
      headerName: "Image",
      width: 90,
      renderCell: ({ row }) => (
        <img
          src={`${row["image_s3_path"]}`}
          alt="employee"
          style={{ width: 70, height: 70, borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "checkin_time",
      headerName: "Time",
      width: 200,
      type: "dateTime",
      renderCell: ({ row }) => (
        <span style={{ fontSize: 12 }}>
          {moment(row.checkin_time).format("h:mm a, Do MMM YYYY")}
        </span>
      ),
    },
  ];

  return (
    <>
      <Dialog
        open={deleteDialogOpened}
        onClose={() => setDeleteDialogOpened(false)}
        maxWidth="md"
      >
        <DeleteDialog
          handleClose={() => setDeleteDialogOpened(false)}
          handleSubmit={handleDeleteImage}
        />
      </Dialog>

      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        PaperProps={{
          component: "form",
          onSubmit: handleModalSubmit,
        }}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Create Customer
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <img
            style={{
              height: 90,
              width: 90,
              borderRadius: "50%",
            }}
            src={record.image_s3_path}
            alt=""
          />
          <TextField
            required
            margin="dense"
            value={userInfo.first_name}
            name="first_name"
            label="First Name"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setUserInfo({
                ...userInfo,
                first_name: e.target.value,
              });
            }}
          />
          <TextField
            required
            margin="dense"
            value={userInfo.last_name}
            name="last_name"
            label="Last Name"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setUserInfo({
                ...userInfo,
                last_name: e.target.value,
              });
            }}
          />
          <TextField
            margin="dense"
            name="email"
            value={userInfo.email}
            label="Email"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setUserInfo({
                ...userInfo,
                email: e.target.value,
              });
            }}
          />
          <TextField
            margin="dense"
            name="member_id"
            value={userInfo.member_id}
            label="Member Id"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setUserInfo({
                ...userInfo,
                member_id: e.target.value,
              });
            }}
            helperText="Only required for gym"
          />
          <Autocomplete
            style={{ marginTop: 24 }}
            value={userInfo.category}
            options={[
              "Member",
              "Guest",
              "Not allowed",
              "Test",
              "Other1",
              "Other2",
            ]}
            onChange={(e, val) => {
              setUserInfo({
                ...userInfo,
                category: val,
              });
            }}
            renderInput={(params) => (
              <TextField label="Category" {...params} required fullWidth />
            )}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>

      <Typography sx={{ mb: 1 }}>
        <strong style={{ color: "#0B0B45" }}>Unrecognized</strong>
      </Typography>
      <MyDataGrid
        rows={dataSource}
        columns={columns}
        density="comfortable"
        rowHeight={60}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        getRowId={(row) => {
          return row.checkin_id + "" + Math.random() * 1000000000;
        }}
        rowsPerPageOptions={[20]}
        disableSelectionOnClick
        pageSize={20}
        page={page}
        onPageChange={(newPage) => {
          handleGetUnApproved({
            page: newPage,
          });
        }}
        pagination
        paginationMode="server"
        rowCount={rowCount}
      />
    </>
  );
}

function DeleteDialog({ handleClose, handleSubmit }) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit();
      }}
    >
      <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          type="submit"
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </form>
  );
}
