import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import http from "../http";
import GlobalLoading from "../Components/loading";

export default function Admin() {
  const [iframe, setIframe] = useState(undefined);
  const handleGetIframeUrl = async () => {
    try {
      GlobalLoading.show();
      const res = await http.get(`/checkin/dashboard`);
      GlobalLoading.hide();
      setIframe(res);
      console.log("res==>>", res);
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  useEffect(() => {
    handleGetIframeUrl();
  }, []);

  return (
    <Container sx={{ marginTop: "20px", height: "90vh" }}>
      {iframe && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={iframe}
          frameBorder="0"
        ></iframe>
      )}
    </Container>
  );
}
