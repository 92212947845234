import React from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, Paper, Container } from "@mui/material";
import EditUser from "../Assets/images/EditUser.svg";
import CreateStaff from "../Assets/images/CcreateStaff.svg";
import Club from "../Assets/images/Club.svg";
import UnverifiedAccounts from "../Assets/images/UnverifiedAccounts.svg";
import Admin from "../Assets/images/Admin.svg";
import Setting from "../Assets/images/setting.svg";
import Customer from "../Assets/images/Customer.svg";
import SelfRegistration from "../Assets/images/self-registration.svg";
import { getUserRole } from "../authUtils";

export default function AdminView() {
  const userRole = getUserRole();
  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Grid container spacing={3} justifyContent="space-evenly">
          {(userRole === "admin" || userRole === "superadmin") && (
            <Card
              label="Dashboard"
              useSvg
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  fill="currentColor"
                  className="bi bi-speedometer"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                  <path
                    fill-rule="evenodd"
                    d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"
                  />
                </svg>
              }
              link="/dashboard"
            />
          )}
          {(userRole === "admin" || userRole === "superadmin") && (
            <Card
              label="Customer Management"
              icon={EditUser}
              link="/update-user"
            />
          )}
          {(userRole === "admin" || userRole === "superadmin") && (
            <Card
              label="Unverified Members"
              icon={UnverifiedAccounts}
              link="/unverified-members"
            />
          )}
          {(userRole === "admin" || userRole === "superadmin") && (
            <Card label="Staff Management" icon={CreateStaff} link="/staff" />
          )}

          {(userRole === "admin" || userRole === "superadmin") && (
            <Card label="Club Management" icon={Club} link="/club" />
          )}

          {(userRole === "admin" || userRole === "superadmin") && (
            <Card label="Setting" icon={Setting} link="/setting" />
          )}
        </Grid>
      </Container>
    </>
  );
}

function Card({ useSvg, svg, icon, label, link = "" }) {
  return (
    <Grid item xs={4}>
      <Link to={link}>
        <Paper
          style={{
            width: "100%",
            padding: "24px 0",
            color: "white",
            background: "#0B0B45",
          }}
          sx={{
            "&:hover": {
              boxShadow: "0px 0px 30px 0px #0B0B4599",
            },
          }}
        >
          <Stack alignItems="center">
            {useSvg ? (
              <>{svg}</>
            ) : (
              <img width={60} height={60} src={icon} alt="" />
            )}

            <p style={{ margin: "20px 0 0", textWrap: "nowrap" }}>{label}</p>
          </Stack>
        </Paper>
      </Link>
    </Grid>
  );
}
