import React from "react";
import { Grid, Stack, Container } from "@mui/material";
import Recognized from "./recognized";
import Unrecognized from "./unrecognized";
import { getToken } from "../authUtils";

export default function Admin() {
  const [recognizedData, setRecognizedData] = React.useState({});
  const [unrecognizedData, setUnrecognizedData] = React.useState({});

  React.useEffect(() => {
    const token = getToken();
    const ws = new WebSocket("wss://test.guest.vision/api/checkin/ws/checkin", [
      token,
    ]);
    ws.onmessage = function (e) {
      if (e.data) {
        try {
          const data = JSON.parse(e.data);
          if (data.is_recognized) {
            setRecognizedData(data);
          } else {
            setUnrecognizedData(data);
          }
        } catch (e) {}
      }
    };

    ws.onopen = () => {
      ws.send("ping");
    };

    ws.onerror = (e) => {
      console.log("err==>>", e);
    };

    ws.onclose = (info) => {
      console.log("info==>>", info);
    };
  }, []);

  return (
    <Container sx={{ marginTop: "20px", height: "100vh" }}>
      <Grid container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={12} md={7} style={{ height: "670px" }}>
          <Recognized insertData={recognizedData} />
        </Grid>

        <Grid item xs={12} md={5}>
          <Stack spacing={2}>
            <div style={{ height: "670px" }}>
              <Unrecognized insertData={unrecognizedData} />
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
